import Form from '@searchtrends/webform-processor';

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

window.loadMapsJs = function() {
    let token = document.head.querySelector('meta[name="maps-token"]');
    let script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + token.content;

    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
    script.onload = () => {
        initMap();
    }
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

if (document.querySelector('.mobile-menu')) {
    document.querySelector('.mobile-menu .closed').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .closed').classList.add('hidden');
        document.querySelector('.mobile-menu .open').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'flex';
    });
    document.querySelector('.mobile-menu .open').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .open').classList.add('hidden');
        document.querySelector('.mobile-menu .closed').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'none';
    });
}

document.querySelector('.menu').addEventListener('click', function (e) {
    document.querySelectorAll('.menu-item').forEach(function (menuItem) {
        menuItem.classList.toggle('show-menu');
    });
});

document.querySelectorAll('.product-grid img').forEach(function (product) {
    product.addEventListener('mouseover', function (e) {
        if (product.src.indexOf("_hover.png") === -1) {
            product.src = product.src.replace('.png', '_hover.png');
        }
    });

    product.addEventListener('mouseout', function (e) {
        if (product.src.indexOf('_hover.png') > 0) {
            product.src = product.src.replace('_hover.png', '.png');
        }
    });

    product.addEventListener('click', function (e) {
        if (product.src.indexOf('_hover.png') === -1) {
            product.src = product.src.replace('.png', '_hover.png');
        } else {
            product.src = product.src.replace('_hover.png', '.png');
        }
    });
});
